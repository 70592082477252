$(document).ready(function () {

    /*
    STICKY MENU
    */
    $('header').sticky({
        topSpacing:0,
        zIndex : 100
    });

    $(document).scroll(function () {
        if ($(document).scrollTop() > 0) {
            $("header").addClass("scrollingdown");
            $("#menu").css({ top: '0.85rem' });
        } else {
            $("header").removeClass("scrollingdown");
            $("#menu").css({ top: '1.25rem' });
        }
    });

    /*
    MENU RESPONSIVE
     */
    $('#menu').on('click', function() {
        /*Ouvre le menu*/
        $('#nav').slideToggle();
        $('nav ul li').css({ padding : '10px' });
        /*Change le picto*/
        let src = $(this).attr('src');
        let newsrc = (src == '/img/icones/menu.svg') ? '/img/icones/close.svg' : '/img/icones/menu.svg';
        $(this).attr('src', newsrc);

        /* Remonte tous les sous-menu */
        $('nav ul li > ul').slideUp();
    })

    /*
    PAGE NAV ACTIF
    */
    let nom = window.location.pathname;
    if(nom==="/pages/cuisine"){
        $('#nav-cuisine').addClass('active');
    }
    else if(nom==="/pages/dressing"){
        $('#nav-dressing').addClass('active');
    }
    else if(nom==="/pages/salle-de-bain"){
        $('#nav-salle-de-bain').addClass('active');
    }
    else if(nom==="/pages/qui-sommes-nous"){
        $('#nav-qui-sommes-nous').addClass('active');
    }
    else if(nom==="/pages/nous-choisir"){
        $('#nav-nous-choisir').addClass('active');
    }
    else if(nom==="/pages/contact"){
        $('#nav-contact').addClass('active');
    }



    if ($(window).width() < 1025) {
        /* Ouverture des sous-menu */
        $('#nav ul li a').on('click', function (e) {
            if ($(this).siblings('ul').length > 0) {
                e.preventDefault();
                $(this).siblings('ul').slideToggle();
                $(this).unbind("click");
            }
        })
    };

    if ($(window).width() < 790) {
        $(window).scroll(function() {
            if($(window).scrollTop() + $(window).height() == $(document).height()){
                $("aside").removeClass("scrollingMobile");
            } else if ($(window).scrollTop() > 100) {
                $("aside").addClass("scrollingMobile");
                if($(".info-box").addClass("fade")){
                    ($(".info-box").removeClass("fade"))
                    ($("#aside-btn").fadeIn(100))}
            } else if ($(document).scrollTop() === 0){
                $("aside").removeClass("scrollingMobile");
            }
        });

        $("#aside-btn").on('click', function () {
            $(this).fadeOut(100);
            $(".info-box").addClass("fade");
        });
    };
    /*
    INPUT DYNAMIQUES
    */
    $('.input-checkbox span, .input-radio span, .input-switch span').on('click', function () {
       let input = $(this)[0].previousElementSibling
        input.checked = !input.checked;
    })

    /*
    ONGLETS
     */
    $('.onglet_title-item').on('click', function (e) {
        e.preventDefault();
        $('.onglet_title-item').removeClass('active')
        $(this).addClass('active');
        let title = $(this).data('title');
        $('.onglet_content-item').addClass('hidden');
        $('.onglet_content-item[data-content="'+title+'"]').removeClass('hidden');
    })

    /*
    TOGGLE PASSWORD
     */
    $('#show-pass').on('click', function () {
        //Change l'attribut du champ
        let attr = $(this).siblings('input').attr('type')
        let newAttr = (attr === 'password') ? 'text' : 'password';
        $(this).siblings('input').attr('type', newAttr)

        //Change le picto
        let classList = $(this).attr('class')
        let newClass = (classList === 'fas fa-eye cursor-pointer') ? 'fas fa-eye-slash cursor-pointer' : 'fas fa-eye cursor-pointer';
        $(this).attr('class', newClass);
    })

    /*
    ENLEVE STYLE P > IMG
    */
    $('p').has('img').css('all', 'unset');

    /*
    POPUP
     */
    $('.popup-open').on('click', function (e) {
        e.preventDefault();
        let id = $(this).data('id');
        $('#popup-'+id).fadeIn().css('display', 'grid')
    })
    /* Fermeture de la popup */
    $('.popup-close').on('click', function() {
        $('.popup').fadeOut();
    })
    $('.popup').on('click', function(e) {
        let popup = $('.popup-content');
        if (!popup.is(e.target) && popup.has(e.target).length === 0) {
            $('.popup').fadeOut();
        }
    });
    $(document).keyup(function(e) {
        if (e.keyCode === 27) {
            $('.popup').fadeOut();
        }
    });

    /*
    ALERT MESSAGES
     */
    $('.alert[role=alert]').on('click', function() {
        $(this).fadeOut('slow', function() {
            $(this).remove()
        })
    });
    setTimeout(function() {
        $('.alert[role=alert]').fadeOut('slow', function() {
            $(this).remove()
        })
    }, 5000);

});

/*
SLIDERS
 */
import Swiper, {Navigation, Pagination, Thumbs, Scrollbar, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Thumbs, Scrollbar, Autoplay ]);

/* Slider hero */
const swiperHeroLength = $('#slider-hero').data('length');
const swiperHero = new Swiper('#slider-hero', {
    loop: true,
    autoplay: true,
    slidesPerView:1,
    navigation: {
        nextEl: '.swiper-hero_next',
        prevEl: '.swiper-hero_prev',
    },
    enabled: (swiperHeroLength > 1)
})

const swiperScroll = new Swiper('.swiperScroll', {
    loop: true,
    autoplay: true,
    slidesPerView: 1,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        },
    pagination: {
        el: '.swiper-scroll-pagination',
        type: 'custom',
        clickable: true,
            renderCustom: function (swiper, current, total){
                return '<span>'+ current +'</span>' +' '+ '<span>'+ total +'</span>'
            }
        },
    scrollbar: {
        el: '.swiper-scrollbar',
        draggable: false,
        },
    breakpoints: {
        320: {
            slidesPerView: 1,
            spaceBetween: 10
        },

        640: {
            slidesPerView: 1,
            spaceBetween: 10
        },

        768:{
            slidesPerView: 1.5,
            spaceBetween: 10
        },

        1024:{
            slidesPerView: 1.75,
            spaceBetween: 10,

        },
        1280:{
            slidesPerView: 2.5,
            centeredSlides:true,
            spaceBetween: 30,
        },
    }


})

const swiperSlider = new Swiper('.swiperSlider', {
    loop: true,
    autoplay: true,
    slidesPerView: 1,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-slider-pagination',
        type: 'custom',
        clickable: true,
        renderCustom: function (swiper, current, total){
            return '<span>'+ current +'</span>' +' '+ '<span>'+ total +'</span>'
        }
    },
    scrollbar: {
        el: '.swiper-slider-scrollbar',
        draggable: false,
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
            spaceBetween: 10
        },
    }


})


